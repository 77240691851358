import React, { FunctionComponent, useRef, useState } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { useClickOutside } from "../../../../hooks/use-click-outside"
import { Icon } from "../../../../components/icon/icon"
import { Link } from "../../../../components/link/link"
import { Book } from "../../scripture-types"
import { getScriptureTranslationId } from "../../scripture-utils"
import styles from "./scripture-header.module.scss"
import { formatSingularBook } from "@features/scriptures/mappers/scripture-reference-mapper"

export interface BookSelectProps {
  books: Book[]
  title: string
  chapter?: number
}

export const BookSelect: FunctionComponent<BookSelectProps> = ({
  books,
  title,
  chapter
}) => {
  const [isOpen, setOpen] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)

  const handleHeaderClick = (): void => setOpen(true)

  const handleListClose = (): void => (isOpen ? setOpen(false) : undefined)

  useClickOutside(listRef, handleListClose)

  const verseReference = `${formatSingularBook(title)} ${
    chapter ? chapter : ""
  }`.trim()

  return (
    <div className={styles.book}>
      <h1 onClick={handleHeaderClick} className={styles.bookHeader}>
        <span>{verseReference}</span>
        <Icon variant="12-right-arrow" className={styles.dropdownIcon} />
      </h1>
      <ul
        className={classNames(styles.allBooks, isOpen && styles.isOpen)}
        ref={listRef}
      >
        {books.map(({ title, slug }) => (
          <li key={slug}>
            <Link onClick={handleListClose} to={slug}>
              <FormattedMessage id={getScriptureTranslationId(title)} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
