import React, { FunctionComponent } from "react"
import { ScriptureDetails } from "../../scripture-types"
import { ScriptureHeader } from "../scripture-header/scripture-header"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { SearchConfig, SearchFilters } from "../../../search/search-types"
import { defaultSearchIndexes } from "../../../../features/search/search-constants"

export interface ScriptureDetailsPageProps {
  scripture: ScriptureDetails
}

const indexes = defaultSearchIndexes.filter((x) => x.id !== "featured")

const defaultFilters: SearchFilters = [
  {
    title: "search-filter-type",
    attribute: "type"
  },
  {
    title: "search-filter-topics",
    attribute: "fields.primaryTopic.fields.name"
  }
]

export const ScriptureDetailsPage: FunctionComponent<
  ScriptureDetailsPageProps
> = ({ scripture }) => {
  let filter = `fields.primaryScriptureReference.start.book:${scripture.book}`
  let filters = defaultFilters

  if (scripture.chapter) {
    filter = `${filter} AND fields.primaryScriptureReference.start.chapter:${scripture.chapter}`
  } else {
    filters = [
      ...filters,
      {
        title: "search-filter-scripture-chapter",
        attribute: "fields.primaryScriptureReference.start.chapter"
      }
    ]
  }

  filter = `${filter} OR fields.entireBookReference.fields.book:${scripture.book}`

  const config: SearchConfig = {
    filters: filter
  }

  return (
    <div>
      <ScriptureHeader scripture={scripture} />
      <SearchResults
        indexes={indexes}
        filters={filters}
        config={config}
        variant="list"
      />
    </div>
  )
}
