import React, { FunctionComponent } from "react"
import { IntlShape } from "react-intl"
import { PageProps } from "../../../types/page"
import { ScriptureDetailsPage } from "../components/scripture-details-page/scripture-details-page"
import { Page } from "../../../components/page/page"
import { PageBreadcrumbs } from "../../../core/breadcrumbs"
import { getScriptureTranslationId } from "../scripture-utils"
import { getScriptureMetadata } from "../mappers/scriptures-mapper"
import { formatSingularBook } from "../mappers/scripture-reference-mapper"

export const ScriptureTemplate: FunctionComponent<
  PageProps<
    null,
    {
      slug: string
      book: string
      chapter: number
    }
  >
> = (props) => {
  const { book, chapter, slug } = props.pageContext.initialData!
  const metadata = getScriptureMetadata(book, chapter)

  const title = (intl: IntlShape) => {
    const baseTitle =
      metadata.title && typeof metadata.title === "function"
        ? metadata.title(intl)
        : metadata.title

    return (
      formatSingularBook(baseTitle as string) +
      " | Resources from Ligonier Ministries"
    )
  }

  return (
    <Page
      {...props}
      metadata={{ ...metadata, title, useTitleTemplate: false }}
      pageOptions={{
        defaultSearchIndex: "scripture",
        headerVariant: "cream",
        breadcrumbs: [
          ...PageBreadcrumbs.Scriptures,
          {
            label: getScriptureTranslationId(book),
            link: `${props.uri}`
          }
        ]
      }}
    >
      <ScriptureDetailsPage scripture={{ book, slug, chapter }} />
    </Page>
  )
}

export default ScriptureTemplate
