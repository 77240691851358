import { BookIndexItem } from "./scripture-types"

export const chapterCountByBook: Record<string, number> = {
  genesis: 50,
  exodus: 40,
  leviticus: 27,
  numbers: 36,
  deuteronomy: 34,
  joshua: 24,
  judges: 21,
  ruth: 4,
  "1-samuel": 31,
  "2-samuel": 24,
  "1-kings": 22,
  "2-kings": 25,
  "1-chronicles": 29,
  "2-chronicles": 36,
  ezra: 10,
  nehemiah: 13,
  esther: 10,
  job: 42,
  psalms: 150,
  proverbs: 31,
  ecclesiastes: 12,
  "song-of-solomon": 8,
  isaiah: 66,
  jeremiah: 52,
  lamentations: 5,
  ezekiel: 48,
  daniel: 12,
  hosea: 14,
  joel: 3,
  amos: 9,
  obadiah: 1,
  jonah: 4,
  micah: 7,
  nahum: 3,
  habakkuk: 3,
  zephaniah: 3,
  haggai: 2,
  zechariah: 14,
  malachi: 4,
  matthew: 28,
  mark: 16,
  luke: 24,
  john: 21,
  acts: 28,
  romans: 16,
  "1-corinthians": 16,
  "2-corinthians": 13,
  galatians: 6,
  ephesians: 6,
  philippians: 4,
  colossians: 4,
  "1-thessalonians": 5,
  "2-thessalonians": 3,
  "1-timothy": 6,
  "2-timothy": 4,
  titus: 3,
  philemon: 1,
  hebrews: 13,
  james: 5,
  "1-peter": 5,
  "2-peter": 3,
  "1-john": 5,
  "2-john": 1,
  "3-john": 1,
  jude: 1,
  revelation: 22
}

export const books: BookIndexItem[] = [
  { id: "genesis", chapters: 50, volume: 1 },
  { id: "exodus", chapters: 40, volume: 1 },
  { id: "leviticus", chapters: 27, volume: 1 },
  { id: "numbers", chapters: 36, volume: 1 },
  { id: "deuteronomy", chapters: 34, volume: 1 },
  { id: "joshua", chapters: 24, volume: 1 },
  { id: "judges", chapters: 21, volume: 1 },
  { id: "ruth", chapters: 4, volume: 1 },
  { id: "1-samuel", chapters: 31, volume: 1 },
  { id: "2-samuel", chapters: 24, volume: 1 },
  { id: "1-kings", chapters: 22, volume: 1 },
  { id: "2-kings", chapters: 25, volume: 1 },
  { id: "1-chronicles", chapters: 29, volume: 1 },
  { id: "2-chronicles", chapters: 36, volume: 1 },
  { id: "ezra", chapters: 10, volume: 1 },
  { id: "nehemiah", chapters: 13, volume: 1 },
  { id: "esther", chapters: 10, volume: 1 },
  { id: "job", chapters: 42, volume: 1 },
  { id: "psalms", chapters: 150, volume: 1 },
  { id: "proverbs", chapters: 31, volume: 1 },
  { id: "ecclesiastes", chapters: 12, volume: 1 },
  { id: "song-of-solomon", chapters: 8, volume: 1 },
  { id: "isaiah", chapters: 66, volume: 1 },
  { id: "jeremiah", chapters: 52, volume: 1 },
  { id: "lamentations", chapters: 5, volume: 1 },
  { id: "ezekiel", chapters: 48, volume: 1 },
  { id: "daniel", chapters: 12, volume: 1 },
  { id: "hosea", chapters: 14, volume: 1 },
  { id: "joel", chapters: 3, volume: 1 },
  { id: "amos", chapters: 9, volume: 1 },
  { id: "obadiah", chapters: 1, volume: 1 },
  { id: "jonah", chapters: 4, volume: 1 },
  { id: "micah", chapters: 7, volume: 1 },
  { id: "nahum", chapters: 3, volume: 1 },
  { id: "habakkuk", chapters: 3, volume: 1 },
  { id: "zephaniah", chapters: 3, volume: 1 },
  { id: "haggai", chapters: 2, volume: 1 },
  { id: "zechariah", chapters: 14, volume: 1 },
  { id: "malachi", chapters: 4, volume: 1 },
  { id: "matthew", chapters: 28, volume: 2 },
  { id: "mark", chapters: 16, volume: 2 },
  { id: "luke", chapters: 24, volume: 2 },
  { id: "john", chapters: 21, volume: 2 },
  { id: "acts", chapters: 28, volume: 2 },
  { id: "romans", chapters: 16, volume: 2 },
  { id: "1-corinthians", chapters: 16, volume: 2 },
  { id: "2-corinthians", chapters: 13, volume: 2 },
  { id: "galatians", chapters: 6, volume: 2 },
  { id: "ephesians", chapters: 6, volume: 2 },
  { id: "philippians", chapters: 4, volume: 2 },
  { id: "colossians", chapters: 4, volume: 2 },
  { id: "1-thessalonians", chapters: 5, volume: 2 },
  { id: "2-thessalonians", chapters: 3, volume: 2 },
  { id: "1-timothy", chapters: 6, volume: 2 },
  { id: "2-timothy", chapters: 4, volume: 2 },
  { id: "titus", chapters: 3, volume: 2 },
  { id: "philemon", chapters: 1, volume: 2 },
  { id: "hebrews", chapters: 13, volume: 2 },
  { id: "james", chapters: 5, volume: 2 },
  { id: "1-peter", chapters: 5, volume: 2 },
  { id: "2-peter", chapters: 3, volume: 2 },
  { id: "1-john", chapters: 5, volume: 2 },
  { id: "2-john", chapters: 1, volume: 2 },
  { id: "3-john", chapters: 1, volume: 2 },
  { id: "jude", chapters: 1, volume: 2 },
  { id: "revelation", chapters: 22, volume: 2 }
]
