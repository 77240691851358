import React, { FunctionComponent, useMemo } from "react"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { PathPrefix } from "../../../../core/constants"
import { Link } from "../../../../components/link/link"
import { ScriptureDetails, Book, BookIndexItem } from "../../scripture-types"
import { chapterCountByBook, books } from "../../scripture-constants"
import { getScriptureTranslationId } from "../../scripture-utils"
import { BookSelect } from "./book-select"
import styles from "./scripture-header.module.scss"

const allScriptures = books.map(
  (book: BookIndexItem): Book => ({
    title: book.id,
    chapters: [],
    slug: `${PathPrefix.Scriptures}/${book.id}`
  })
)

export interface ScriptureHeaderProps {
  scripture: ScriptureDetails
}

export const ScriptureHeader: FunctionComponent<ScriptureHeaderProps> = ({
  scripture: { book, chapter }
}) => {
  const { formatMessage } = useIntl()

  const currentBook = useMemo(
    () => allScriptures.find((bookItem) => bookItem.title === book)!,
    [book]
  )

  const currentBookChapterCount = useMemo(
    () => (currentBook ? chapterCountByBook[currentBook.title] : null),
    [currentBook]
  )

  if (!currentBook) {
    return null
  }

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <BookSelect
          books={allScriptures}
          title={formatMessage({
            id: getScriptureTranslationId(book)
          })}
          chapter={chapter}
        />
        <ul className={styles.chapterNumbers}>
          <li className={styles.allChaptersLink}>
            <Link to={currentBook.slug}>
              <FormattedMessage id="scriptures-all-chapters" />
            </Link>
          </li>
          {[...Array(currentBookChapterCount)].map((_, key) => {
            const chapterNumber = key + 1
            const chapterClassNames = classNames(styles.chapterLink, {
              [styles.currentChapter]: chapterNumber === chapter
            })

            const baseBookSlug =
              currentBook.slug.substr(-1) === "/"
                ? currentBook.slug.substring(0, currentBook.slug.length - 1)
                : currentBook.slug

            return (
              <li key={chapterNumber} className={chapterClassNames}>
                <Link to={`${baseBookSlug}-${chapterNumber}`}>
                  {chapterNumber}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </header>
  )
}
