import { IntlShape } from "react-intl"
import { ScripturesPageQuery } from "../../../../graphql/types"
import { books } from "../scripture-constants"
import { Metadata } from "../../../types/metadata"
import { TranslateableString } from "../../../types/intl"
import { Book, Volume, Chapter } from "../scripture-types"
import { getScriptureTranslationId } from "../scripture-utils"

const volumeNames = {
  1: "old-testament",
  2: "new-testament"
}

export const mapSourceScripturesToView = (
  queryResult: ScripturesPageQuery
): Volume[] => {
  const references = queryResult.allScriptureReference.nodes

  const getChaptersByBook = (bookID: string): Chapter[] => {
    return references
      .filter((reference) => reference.book === bookID && reference.chapter)
      .map<Chapter>((reference) => ({
        chapter: reference.chapter || 0,
        slug: `${reference.book}-${reference.chapter}` // TODO: Remove this - it can be derived on the fly. I'm not sure we need those intermediary types at all.
      }))
  }

  const volumes: Volume[] = [
    {
      title: volumeNames[1],
      books: []
    },
    {
      title: volumeNames[2],
      books: []
    }
  ]

  books.forEach((book) => {
    const newBook: Book = {
      slug: book.id,
      title: book.id, // TODO: Remove title - it needs to be localised anyway,
      chapters: getChaptersByBook(book.id)
    }

    volumes[book.volume - 1].books.push(newBook)
  })

  return volumes
}

export const getScriptureMetadata = (
  book: string,
  chapter: number
): Partial<Metadata> => {
  const title = (intl: IntlShape) => {
    const bookTitle = intl.formatMessage({
      id: getScriptureTranslationId(book)
    })

    return chapter ? `${bookTitle} ${chapter}` : bookTitle
  }

  const description: TranslateableString = (intl: IntlShape) => {
    const descriptionTemplate = intl.formatMessage({
      id: "scripture-description"
    })
    const bookTitle = intl.formatMessage({ id: `scripture-${book}` })

    return descriptionTemplate.replace("[book]", bookTitle)
  }

  return {
    title,
    description
  }
}
